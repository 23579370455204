import React from 'react';

const Message = ({ message, time }) => {
    const isUser = message.sender === 'user';
    const avatarSrc = isUser ? '/doctor.png' : '/llm.png'; // Correct relative path

    return (
        <div className={`message ${message.sender}`}>
            {!isUser && <img src={avatarSrc} alt="Avatar" />}
            <div className="bubble">
                <p>{message.text}</p>
                <span className="timestamp">{time}</span>
            </div>
            {isUser && <img src={avatarSrc} alt="Avatar" />}
        </div>
    );
};

export default Message;
