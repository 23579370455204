import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Message from './Message';
import {variables} from "./variables";

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    useEffect(() => {
        // Listen for custom events to clear chat
        const handleClearChat = () => {
            setMessages([]);
        };

        window.addEventListener('clearChat', handleClearChat);

        return () => {
            window.removeEventListener('clearChat', handleClearChat);
        };
    }, []);

    const sendMessage = async () => {
        if (!input.trim()) return;

        const newMessage = { sender: 'user', text: input, time: new Date().toLocaleTimeString() };
        setMessages(prevMessages => [...prevMessages, newMessage]);

        const chatHistory = messages.map(msg => [msg.sender, msg.text]);

        try {
            // First, initiate the chat
            await axios.post(variables.API_URL + '/chat', {
                chat_history: chatHistory,
                question: input
            });

            // Then, connect to the EventSource for streaming response
            const eventSource = new EventSource(variables.API_URL + '/chat_stream');

            let botResponse = '';
            eventSource.onmessage = (event) => {
                botResponse += event.data;
                const botMessage = { sender: 'bot', text: botResponse, time: new Date().toLocaleTimeString() };
                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    // Ensure we only modify the last message if it is from the bot
                    if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].sender === 'bot') {
                        updatedMessages[updatedMessages.length - 1] = botMessage;
                    } else {
                        updatedMessages.push(botMessage);
                    }
                    return updatedMessages;
                });
            };

            eventSource.onerror = (error) => {
                console.error('Error receiving streamed response:', error);
                eventSource.close();
            };

        } catch (error) {
            console.error('Error sending message:', error);
        }

        setInput('');
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            await sendMessage();
        }
    };

    return (
        <div className="chat-container">
            <div className="messages">
                {messages.map((message, index) => (
                    <Message key={index} message={message} time={message.time} />
                ))}
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button onClick={sendMessage}>Отправить</button>
            </div>
        </div>
    );
};

export default Chat;
