import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UpdatePatientInfo.css';
import {variables} from "./variables";

const UpdatePatientInfo = () => {
    const [patientInfo, setPatientInfo] = useState('');
    const [selectedOption, setSelectedOption] = useState('default1');
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    useEffect(() => {
        fetchDefaultPatientInfo(selectedOption);
    }, [selectedOption]);

    const fetchDefaultPatientInfo = async (option) => {
        try {
            const response = await axios.get(variables.API_URL + '/default_patient_info', {
                params: { option }
            });
            setPatientInfo(response.data.info);
        } catch (error) {
            console.error('Error fetching default patient information:', error);
        }
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleUpdate = async () => {
        try {
            await axios.post(variables.API_URL + '/update_patient_info', { info: patientInfo });
            alert('Информация обновлена.');
            // Custom event to clear chat
            window.dispatchEvent(new CustomEvent('clearChat'));
        } catch (error) {
            console.error('Error updating patient information:', error);
        }
    };

    return (
        <div className={`update-patient-info-container ${isPanelOpen ? 'open' : ''}`}>
            <button className="toggle-button" onClick={() => setIsPanelOpen(!isPanelOpen)}>
                {isPanelOpen ? 'Закрыть' : 'Информация о пациенте'}
            </button>
            {isPanelOpen && (
                <div className="update-patient-info">
                    <h3>Информация о пациенте</h3>
                    <div className="default-options">
                        <label>
                            <input
                                type="radio"
                                value="default1"
                                checked={selectedOption === 'default1'}
                                onChange={handleOptionChange}
                            />
                            Пациент 1
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="default2"
                                checked={selectedOption === 'default2'}
                                onChange={handleOptionChange}
                            />
                            Пациент 2
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="custom"
                                checked={selectedOption === 'custom'}
                                onChange={handleOptionChange}
                            />
                            Описать своего пациента
                        </label>
                    </div>
                    <textarea
                        value={patientInfo}
                        onChange={(e) => setPatientInfo(e.target.value)}
                        placeholder="Введите здесь информацию о пациенте"
                        rows={10}
                        cols={50}
                        disabled={selectedOption !== 'custom'}
                    />
                    <button onClick={handleUpdate}>Обновить</button>
                </div>
            )}
        </div>
    );
};

export default UpdatePatientInfo;
