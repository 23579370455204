// frontend/chatbot/src/App.js
import React from 'react';
import Chat from './components/Chat';
import UpdatePatientInfo from './components/UpdatePatientInfo';
import './App.css';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceWorker.js')
            .then(registration => {
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }, error => {
                console.log('ServiceWorker registration failed: ', error);
            });
    });
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Sechenov Patient</h1>
      </header>
      <div className="main-content">
        <Chat />
        <UpdatePatientInfo />
      </div>
    </div>
  );
}

export default App;
